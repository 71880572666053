import React from "react";
// import { Link } from "react-router-dom";
import "../scss/main.scss";
import "../scss/utility.scss";
import "../scss/custom.scss";
import Tool from "./ToolCard";

function AdminTools() {
  return (
    <div className="container-fluid vstack gap-6 p-3 ">
      <div className="container-fluid vstack gap-6 p-3 ">
        <h3 className="my-3">Available tools</h3>
        <div className="row g-6">
       
            <Tool
              name="Product availability check"
              department="Ecommerce"
              desc="Check which freshcotton products are hidden."
            />
            <Tool 
              name="Test tool"
              department="Marketing"
              desc="This is not a real tool"
              />
            <Tool 
              name="Test tool 2"
              department="department"
              desc="This is not a real tool"
            />
            
        </div>
      </div>
    </div>
  );
}

export default AdminTools;
