import React from 'react'

function AdminHeader(props) {

  return (


  
    <header className="bg-surface-primary border-bottom p-2">
        <div className="container-fluid">
          <div className="mb-npx">
            <div className="row align-items-center">
              <div className="col-sm-6 col-12 mb-4 mb-sm-0">
                <h1 className="h2 ls-tight">
                  <span className="d-inline-block me-3">{props.emoij}</span>{props.text} {props.username}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </header>
  )
}

export default AdminHeader