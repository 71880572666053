
import { useContext } from 'react';
import { Outlet, Navigate } from 'react-router-dom'
import { UserContext } from '../UserContext'

const PrivateRoute = () => {
  const {token} = useContext(UserContext);
  
    let auth = !!token;
  return (

    auth ? <Outlet/> : <Navigate to="/login"/>
  )
}

export default PrivateRoute



// function LogoutButton() {
//   const [isLoggedOut, setLoggedOut] = useState(false);

//   function handleLogout() {
//     // Perform logout operations, such as clearing authentication data from storage
//     setLoggedOut(true);
//   }

//   return (
//     <button onClick={handleLogout}>
//       Logout
//     </button>
//   );
// }

// als er op de logout button gedrukt wordt dan moet de auth token verwijderd worden en geredirect worden naar de login pagina


