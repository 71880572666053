import React from "react";
import { Link } from "react-router-dom";
import "../scss/admin.scss"

function Tool(props) {
  return (
    <div className="col-xl-3 col-sm-6 col-12">
      <div className="card">
        <Link className="nav-link text-decoration-none text-dark" to="/pac">
          <div className="card-body">
            <div className="row">
              <div className="col">
                <span className="h6 font-semibold text-muted text-sm d-block mb-2">
                  {props.department}
                </span>
                <span className="h3 font-bold mb-0">{props.name}</span>
              </div>
              <div className="col-auto">
                <div className="icon icon-shape bg-tertiary text-white text-lg rounded-circle">
                  <i className="bi bi-credit-card"></i>
                </div>
              </div>
            </div>
            <div className="mt-2 mb-0 text-sm">
              <span className="text-nowrap text-xs text-muted">
                {props.desc}
              </span>
            </div>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default Tool;
