/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext }from "react";
// import Navbar from "./Navbar";
import { Link } from "react-router-dom";
import "./scss/admin.scss"
import { UserContext } from './UserContext'
import {Speedometer, PersonFill, BoxArrowLeft, ListUl} from 'react-bootstrap-icons';




function SecondNavbar() {

  const {handleLogout} = useContext(UserContext);;


  return (
  <div>
    <nav
      className="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg admin-nav"
      id="navbarVertical">
      <div className="container-fluid">
        <button
          className="navbar-toggler ms-n2"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#sidebarCollapse"
          aria-controls="sidebarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>{" "}
        <a className="navbar-brand py-lg-2 mb-lg-5 px-lg-6 me-0" href="/">
          <img className="mx-4 image-fluid" src="https://www.freshcotton.com/bundles/freshcotton/logo.svg?16674859312643" alt="..."/>
        </a>
        <div className="collapse navbar-collapse" id="sidebarCollapse">
          <ul className="navbar-nav">
            <li className="nav-item">
            <Link className="nav-link" to="/">
                <Speedometer /> Dashboard
              </Link>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/account">
                <PersonFill /> 
                Account
            </Link>
            </li>
            <li className="nav-item">
              <a className="nav-link" onClick={handleLogout}>
                <BoxArrowLeft /> 
                Logout 
              </a>
            </li>
          </ul>
          <hr className="navbar-divider my-5 opacity-70" />
          <ul className="navbar-nav">
            <li>
              <span className="nav-link text-xs font-semibold text-uppercase text-muted ls-wide">
                Tools
              </span>
            </li>
            <li className="nav-item">
            <Link className="nav-link" to="/pac">
              <ListUl /> 
              Product availability check
            </Link>
            </li>
            
          </ul>
    
        </div>
      </div>
    </nav>
    </div>
  );
}

export default SecondNavbar;
