/* eslint-disable no-unused-vars */
import React, { useContext } from 'react';
import AdminNav from "../AdminNav";
import AdminHeader from "../components/AdminHeader";
import "../scss/admin.scss"
import { UserContext } from '../UserContext'

function Account() {

    const {handleLogout, loginCredentials} = useContext(UserContext);;
 

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
        <AdminNav />
        <div className="body-home">
            <AdminHeader 
            emoij="👤"
            text="Account"
            // username={loginCredentials}
            />


        <div className="container-fluid vstack gap-6 p-3 ">
          <div className="container-fluid vstack gap-6 p-3 ">
            <h3 className="my-3">Account Info</h3>
            <p>Ingelogd als: <b>{loginCredentials[0]}</b></p>
            <p>Iets aanpassen in je account? Dit moet in <a href='https://www.freshcotton.com/admin#/sw/profile/index/general' target="_blank" rel="noopener noreferrer">shopware</a>.</p>
            <div className='btn btn-danger my-4' onClick={handleLogout}>Logout</div>


          </div>
        </div>
        </div>
    </div>
  )
}

export default Account

