import React, {useContext} from "react"
import { UserContext } from "../UserContext";
import AdminNav from "../AdminNav";
import AdminHeader from "../components/AdminHeader";
import AdminTools from "../components/AvailableTools";
import "../scss/main.scss";
import "../scss/utility.scss";
import "../scss/custom.scss";

function Home() {
  const {loginCredentials} = useContext(UserContext);

  return (
    <div className="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
      <AdminNav />

      <div className="body-home">
        <AdminHeader 
        emoij="👋"
        text="Welcome"
        username={loginCredentials[0]}
        />
        <AdminTools />

        <div className="container-fluid vstack gap-6 p-3 ">
          <div className="container-fluid vstack gap-6 p-3 ">
            <h3 className="my-3">Submit your struggles!</h3>
            <p>Want a tool for your department? Let us know what your struggles are. </p>


          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
